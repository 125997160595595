import React, { useState } from 'react';
import './PopupForm.css';
import { RxCross1 } from "react-icons/rx";


const PopupForm = ({ isOpen, onClose, userData, onEdit }) => {
    const initialUserData = {
        name: '',
        email: '',
        mobile: '',
        website: '',
        address: '',
        company: '',
        // Add more fields as needed
    };
    const [editedUserData, setEditedUserData] = useState(userData || initialUserData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onEdit(editedUserData);
        onClose();
    };

    return (
        <div>
            {isOpen && (
                <div className="modal" >
                    <div className="modal-content">
                        <span className="close" onClick={onClose}><RxCross1 /></span>
                        <h3>Basic Model</h3>

                        <form onSubmit={handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={editedUserData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="name">Name </label>
                            </div>


                            <div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={editedUserData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="email">Email </label>
                            </div>


                            <div>
                                <input
                                    type="tel"
                                    id="mobile"
                                    name="mobile"
                                    value={editedUserData.mobile}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="mobile">Mobile </label>
                            </div>


                            <div>
                                <input
                                    type="url"
                                    id="website"
                                    name="website"
                                    value={editedUserData.website}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="website">Website </label>
                            </div>

                        </form>
                        <div className="button-container">
                            <button className='ok' onClick={handleSubmit}>OK</button>
                            <button className='cancel' type="button" onClick={onClose}>Cancel</button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default PopupForm;
