import React, { useState } from 'react';
import { FaRegHeart, FaHeart } from "react-icons/fa6";
import { AiOutlineEdit } from "react-icons/ai";
import { AiFillDelete } from 'react-icons/ai';
import PopupForm from './PopupForm';
import userData from './UserData';

const UserList = () => {
    const [usersData, setUsersData] = useState(userData);
    const [selectedUser, setSelectedUser] = useState(null);

    const openEditPopup = (user) => {
        setSelectedUser(user);
    };

    const closeEditPopup = () => {
        setSelectedUser(null);
    };

    const handleEdit = (editedData) => {
        setUsersData((prevUsersData) =>
            prevUsersData.map((user) => (user.id === editedData.id ? editedData : user))
        );
        closeEditPopup();
    };

    const handleLike = (userId) => {
        setUsersData((prevUsersData) =>
            prevUsersData.map((user) =>
                user.id === userId ? { ...user, liked: !user.liked } : user
            )
        );
    };

    const handleDelete = (userId) => {
        setUsersData((prevUsersData) =>
            prevUsersData.filter((user) => user.id !== userId)
        );
    };
    // const img =
    //     'https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=';
    return (
        <div className='container' style={{}}>
            {usersData.map((user) => (
                <div key={user.id} className="user">
                    <div className="user_image">
                        {/* Display user image here */}
                        <img src={user.imageUrl} alt="" />
                    </div>
                    <div className="info">
                        <div className="additional">
                            <div>
                                <h4>{user.name}</h4>
                            </div>
                            <div>
                                <img src="./Images/email.png" alt="" height={19} width={19} />
                                <span>{user.email}</span>
                            </div>
                            <div>
                                <img src="./Images/mobile.png" alt="" height={19} width={19} />
                                <span>{user.mobile}</span>
                            </div>
                            <div>
                                <img src="./Images/web.png" alt="" height={20} width={20} />
                                <span>{user.website}</span>
                            </div>
                        </div>
                        <div className="actions">
                            <div className="like" onClick={() => handleLike(user.id)}>
                                {user.liked ? <FaHeart style={{ color: 'red' }} /> : <FaRegHeart />}
                            </div>
                            <div style={{ color: '#e8e8e8' }}>|</div>
                            <div className="edit" onClick={() => openEditPopup(user)}>
                                <AiOutlineEdit />
                            </div>
                            <div style={{ color: '#e8e8e8' }}>|</div>
                            <div className="delete" onClick={() => handleDelete(user.id)}>
                                <AiFillDelete style={{ fontWeight: 'bold' }} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {selectedUser && (
                <PopupForm isOpen={true} onClose={closeEditPopup} userData={selectedUser} onEdit={handleEdit} />
            )}
        </div>
    );
};

export default UserList;
