import React from 'react'
import User from './component/User'

const App = () => {
  return (
    <div>
      <User />
    </div>
  )
}

export default App
